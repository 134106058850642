export const Quotes = [
    {
      "id": "elonmusk",
      "name": "Elon Musk",
      "quote": "I think people can choose to be not ordinary. 🚀",
      "url": "https://twitter.com/elonmusk",
      "image" : "musk"
    },
    {
      "id": "WarrenBuffett",
      "name": "Warren Buffett",
      "quote": "Praise by name, criticise by category. 🚀",
      "url": "https://twitter.com/WarrenBuffett",
      "image" : "buffet"
    },
    {
      "id": "sama",
      "name": "Sam Altman",
      "quote": "The days are long but the decades are short. 🚀",
      "url": "https://twitter.com/sama",
      "image" : "sam"
    },
    {
      "id": "sb",
      "name": "Sergey Brin",
      "quote": "Too many rules stifle innovation. 🚀",
      "url": "https://en.wikipedia.org/wiki/Sergey_Brin",
      "image" : "sergey"
    },
    {
      "id": "peterthiel",
      "name": "Peter Thiel",
      "quote": "moving first is a tactic, not a goal. 🚀",
      "url": "https://twitter.com/peterthiel",
      "image" : "thiel"
    },
    {
      "id": "cs",
      "name": "Clara Shih",
      "quote": "Listen more than you talk. 🚀",
      "url": "https://en.wikipedia.org/wiki/Clara_Shih",
      "image" : "clara"
    },
    {
      "id": "sbl",
      "name": "Sara Blakely",
      "quote": "You've got to embrace what you don't know. 🚀",
      "url": "https://en.wikipedia.org/wiki/Sara_Blakely",
      "image" : "sb"
    },
    {
      "id": "naval",
      "name": "Naval Ravikant",
      "quote": "Arm Yourself With Specific Knowledge, 🚀",
      "url": "https://twitter.com/naval",
      "image" : "naval"
    },
    {
      "id": "steve_jobs",
      "name": "Steve Jobs",
      "quote": "Stay hungry, stay foolish. 🚀",
      "url": "https://en.wikipedia.org/wiki/Steve_Jobs",
      "image" : "jobs"
    },
    {
      "id": "pmarca",
      "name": "Marc Andreessen",
      "quote": "Adaptability is key. 🚀",
      "url": "https://twitter.com/pmarca",
      "image" : "marc"
    }
  ]