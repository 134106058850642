import React, {Component} from 'react';
import styled from 'styled-components';
import { Quotes } from '../db';
import naval from '../Images/naval.png'
import buffet from '../Images/buffet.png'
import clara from '../Images/clara.jpg'
import jobs from '../Images/jobs.jpg'
import marc from '../Images/marc.jpg'
import musk from '../Images/musk.png'
import sam from '../Images/sam.jpg'
import sb from '../Images/sb.png'
import sergey from '../Images/sergey.png'
import thiel from '../Images/thiel.jpg'


const mappings = {
    "naval" : naval,
    "buffet" : buffet,
    "clara" : clara,
    "jobs" : jobs,
    "marc" : marc,
    "musk" : musk,
    "sam" : sam,
    "sb" : sb,
    "sergey" : sergey,
    "thiel" : thiel
}


const StyledQuote = styled.span`
    color: ${props => props.HeaderActive ? 'brown' : '#19FF4C'};
    font-weight : 600;
    cursor : pointer;
`

const StyledDropdown = styled.div`
    position: absolute;
    background-color: ${props => props.HeaderActive ? 'gainsboro' : '#18191B'};
    opacity: 1;
    top: ${props => props.HeaderActive ? '33px' : '31px'}
    right: 0px;
    z-index: 10;
    padding-bottom: 5px;
    padding-top: 10px;
`


const Quit = styled.div`
    border-top: 1px solid ${props => props.HeaderActive ? '#bababa' : '#f1f1f1'};
    padding-top: 5px;
    padding-left: 10px;
`

const LinkedName = styled.a`
    color: ${props => props.HeaderActive ? 'black' : 'white'};
    text-decoration : none;
    display : flex;
    alignItems : center;
`


class WisdomBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            index : 0,
            open : true
        }
    }
    

    componentDidMount(){
        this.quoteRefresher();
    }

    quoteRefresher = () => {
        setInterval(() => {
           this.setState(
               {index: (this.state.index + 1)%10}
           ) 
        }, 2500);
    }


    dropDownToggle = () => {
        this.setState(
            {
                open : !this.state.open
            }
        );
    }

    render() {
        const {HeaderActive} = this.props;
        return(
            <div style = {{display: "flex", position: "relative", flexDirection : "column"}} onClick = { () => (this.dropDownToggle())}>
                <StyledQuote HeaderActive = {HeaderActive}>
                    {Quotes[this.state.index].quote}
                </StyledQuote>
                {
                    this.state.open && (
                        <StyledDropdown HeaderActive = {HeaderActive}>
                            <div style = {{display : "flex",padding : "0px 20px",paddingBottom : "10px", width : "185px"}}>
                                <img src = {mappings[Quotes[this.state.index].image]} style = {{height : "35px", width : "35px", borderRadius : "50px"}} />
                                <LinkedName HeaderActive = {HeaderActive} href={Quotes[this.state.index].url} target="_blank" ><span style = {{fontWeight : "400"}}>{Quotes[this.state.index].name}</span></LinkedName>
                            </div>
                            <Quit HeaderActive = {HeaderActive} >
                                <span>Quit</span>
                            </Quit>
                        </StyledDropdown>
                    )
                }
                
            </div>
        )
    }
      
}

export default WisdomBar;