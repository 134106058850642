import React from 'react';

const HamburgerIcon = ({
    style = {},
    fill = 'white',
    width = '100%',
    height = '100%',
    viewBox = '0 0 50 50',
  }) => 
  <div>
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z " fill={fill} />
    </svg>
  </div>

    export default HamburgerIcon;