import React, {Fragment} from 'react';
import styled from 'styled-components';
import AppleIcon from '../Icons/AppleIcon';
import SearchIcon from '../Icons/SearchIcon';
import HamburgerIcon from '../Icons/HambugerIcon';
import WifiIcon from '../Icons/WifiIcon';
import BatteryIcon from '../Icons/BatteryIcon';
import WisdomBar from './WisdomBar';

const StyledHeader = styled.div`
    background-color : ${props => props.HeaderActive ? 'gainsboro' : '#18191B'};
    color: ${props => props.HeaderActive ? 'black' : 'white'};
    display : flex;
    justify-content : space-between;

`

const LeftHeader = styled.div`
    display : flex;
    padding-left : 10px;
    align-items : center;

    & span {
        padding: 5px 10px;
    }

`

const RightHeader = styled.div`
    display : flex;
    padding-right : 10px;
    align-items: center;

    & span {
        padding: 5px 10px;
    }

`



const Header = ({HeaderActive, isMobile}) => (
    <Fragment>
    {!isMobile ? (
    <StyledHeader HeaderActive = {HeaderActive} >
        <LeftHeader>
            <div style = {{display : "flex", margin : "auto", paddingRight : "10px"}}>
                <AppleIcon height = "20" width = "20" fill = {HeaderActive ? "black" : "white"} />
            </div>
            <span style = {{fontWeight : "bold"}} >Finder</span>
            <span>File</span>
            <span>Edit</span>
            <span>View</span>
            <span>Go</span>
            <span>Window</span>
            <span>Help</span>
        </LeftHeader>
        <RightHeader>
            <WisdomBar HeaderActive = {HeaderActive} />
           
            <div style = {{display : "flex", margin : "auto", padding: "0px 10px"}}>
                <WifiIcon height = "20" width = "20" fill = {HeaderActive ? "black" : "white"} />
            </div>
            <span>42%</span>
            <div style = {{display : "flex", margin : "auto", padding: "5px 10px 0px 0px"}}>
                <BatteryIcon height = "20" width = "20" fill = {HeaderActive ? "black" : "white"} />
            </div>
            <span>Sat 2:30 PM</span>
            <span style = {{fontWeight : "bold"}}>Elon Musk</span>
            <div style = {{display : "flex", margin : "auto",padding: "0px 10px"}}>
                <SearchIcon height = "20" width = "20" fill = {HeaderActive ? "black" : "white"} />
            </div>
            <div style = {{display : "flex", margin : "auto", padding: "0px 10px"}}>
                <HamburgerIcon height = "20" width = "20" fill = {HeaderActive ? "black" : "white"} />
            </div>
        </RightHeader>
    </StyledHeader>) : (

    <StyledHeader HeaderActive = {HeaderActive} >
    
    </StyledHeader>

    )
    }
    </Fragment>
    )


export default Header;