import React, {Component} from 'react';
import './App.css';
import Header from './Components/Header';
import Hero from './Components/Hero';
import styled from 'styled-components'




class App extends Component{
  constructor(props){
    super(props);

    this.state = {
      HeaderActive : false
    }
  }

  toggleHeaderState = () => {
    this.setState({
      HeaderActive : !this.state.HeaderActive
    });
  }

  render () {
    let isMobile = window.innerWidth < 800;

    return (
      <div style = {{height : "100vh", display : "flex", flexDirection: "column"}} >
        <Header HeaderActive = {this.state.HeaderActive} isMobile = {isMobile} />
        <Hero isMobile = {isMobile} toggleHeaderState = {this.toggleHeaderState} />
    </div>
    )
  }
}

export default App;
