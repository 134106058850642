import React from 'react';

const BatteryIcon = ({
    style = {},
    fill = 'white',
    width = '100%',
    height = '100%',
    viewBox = '0 0 512 512',
  }) => 
  <div>
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M489.657,196.47h-26.229v-40.024c0-18.48-15.035-33.515-33.515-33.515H33.515C15.035,122.931,0,137.965,0,156.445v199.109    c0,18.48,15.035,33.515,33.515,33.515h396.399c18.48,0,33.515-15.035,33.515-33.515V315.53h26.229    c12.32,0,22.343-10.023,22.343-22.343v-74.375C512,206.493,501.977,196.47,489.657,196.47z M113.377,366.726H33.515    c-6.16,0-11.172-5.012-11.172-11.172V156.445c0-6.16,5.012-11.172,11.172-11.172h79.862V366.726z M441.085,355.555    c0,6.16-5.012,11.172-11.172,11.172H135.72V145.274h294.194c6.16,0,11.172,5.012,11.172,11.172V355.555z M489.657,293.187h-26.229    v-74.375h26.229l0.015,74.375C489.67,293.186,489.666,293.187,489.657,293.187z" fill={fill} />
    </svg>
  </div>

    export default BatteryIcon;