import React, {Fragment} from 'react'
import styled from 'styled-components'

const StyledHero = styled.div`
    background-image :  ${props => props.isMobile ? ("url(https://s3-us-west-2.amazonaws.com/loop8/wisdombar/macos_web.png)") : ("url(https://s3-us-west-2.amazonaws.com/loop8/wisdombar/macos_sierra_4k-3840x2400.jpg)")};
    background-size : ${props => props.isMobile ? ("auto") : ("cover")};
    width : 100%;
    height : 100%;
    background-color: black;
    display : flex;
    align-items: center;
    justify-content: center;
`

const StickyNote = styled.div`
    position : absolute;
    top : 150px;
    right : 20px;
    background-color: #FFF2AB;
    color : black;
    height : 250px;
    width : 300px;
    z-index : 100;
    opacity : 0.95;
    display : flex;
    flex-direction : column;
    text-align : center;

    -webkit-box-shadow: -1px -2px 11px 1px rgba(69,69,69,1);
    -moz-box-shadow: -1px -2px 11px 1px rgba(69,69,69,1);
    box-shadow: -1px -2px 11px 1px rgba(69,69,69,1);
    animation: bounce 1s ease-in-out 1(turn);
    animation-play-state : paused;
          
`

const StyledButton = styled.button`
    padding : 10px 30px;
    background-color : red;
    color : white; 
    border: none;
    border-radius : 40px;
    font-weight : bold;
    font-size : 15px;

    :hover {
        cursor : pointer;
        transform : scale(1.2,1.2)
    }
`

const ButtonContainer = styled.div`
    flex-grow: 2;
    display: flex;
    align-items: center;
    justify-content: center;
`




const Hero = ({isMobile, toggleHeaderState}) => (
    <StyledHero isMobile = {isMobile}>
    {!isMobile ? (
        <Fragment>
        <div style = {{width : "100%", height : "100%", backgroundColor : "black" , opacity : "0.3"}}>
        </div>

        <StickyNote className = "animated bounce" onMouseEnter = {() => toggleHeaderState()} onMouseLeave = {() => toggleHeaderState()}>
            <div style = {{backgroundColor: "#FFEB81", height : "40px", width : "100%", marginBottom : "10px"}}>
                <span style = {{fontSize : "25px", fontWeight : "bold"}}>WisdomBarOSX ☝️</span>
            </div>
            
            <span style = {{fontSize : "17px", textAlign: "left", padding : "10px", paddingTop : "15px"}}>Get 200+ Wisdom Quotes on Entrepreneurship and Life, right in your Mac-Menu-Bar.</span>
            <ButtonContainer>
                <a href = "https://gumroad.com/l/WisdomBarOSX/47qrawe" target = "_blank">
                    <StyledButton>😍 Buy Now - $ 2.99</StyledButton>
                </a>
            </ButtonContainer>
        </StickyNote>
        </Fragment>
    ) : (
        <div style = {{ backgroundColor : "black", opacity : "0.9", color : "white", padding : "10px", borderRadius : "10px", display : "flex", flexDirection : "column", marginBottom : "30px", textAlign : "center", "width" : "80%"}}>
          <span style = {{fontSize : "25px", fontWeight : "bold", zIndex : "10000"}}>WisdomBarOSX ☝️</span>
          <span style = {{ paddingTop : "15px"}}>Get 200+ Wisdom Quotes on Entrepreneurship and Life for your Mac-Menu-Bar .</span>
          <div style = {{paddingTop : "30px", margin : "auto"}}>
                <a href = "https://gumroad.com/l/WisdomBarOSX/47qrawe" target = "_blank">
                    <StyledButton>😍 Buy Now - $ 2.99</StyledButton>
                </a>
          </div>
          <div style = {{paddingTop : "10px",paddingBottom : "10px", margin : "auto"}}>
                <a href = "https://www.youtube.com/watch?v=Zdix561NNUY" target = "_blank">
                    <StyledButton style = {{backgroundColor: "#001fe3"}}>Watch Video</StyledButton>
                </a>
            </div>
        </div>
    )}
    </StyledHero>
)

export default Hero;